import { AxiosResponse } from 'axios';
import { GptRunModelsData } from 'src/types/Services/GPT/gpt';

interface GptResult {
  count: number;
}

export interface ProcessGptResponse {
  answer: GptResult[]; // Adjust 'any' to match the specific type for 'result'
  questionId: string;
  summary: string | undefined;
}

export const processGptResponse = (res: AxiosResponse<GptRunModelsData>): ProcessGptResponse | null => {
  if (res && res.data && res.data.completions) {
    const keys = Object.keys(res.data.completions);
    for (let i = 0; i < keys.length; i++) {
      const completion = res.data.completions[keys[i]];

      if (completion && !completion.result && !completion.summary) {
        return {
          answer: [{ count: 0 }], // Adjusted to match the type GptResult
          questionId: '',
          summary: 'Sorry, we are unable to provide a response to this question at the moment.',
        };
      }

      if (completion && (completion.result || completion.summary)) {
        if (completion.result?.length && completion.result.length > 0) {
          return {
            answer: completion.result,
            questionId: completion.questionId,
            summary: completion.summary?.data,
          };
        }

        // if the array is empty, we return {count: 0} so that the frontend does not crash
        return {
          answer: [{ count: 0 }], // Adjusted to match the type GptResult
          questionId: completion.questionId,
          summary: completion.summary?.data,
        };
      }
    }
  }
  return null;
};
