import { useUser } from 'contexts/UserContext';
import React, { useState } from 'react';
import { CommentInput } from '@/components/ui/comment-input';
import { ArrowUp } from 'lucide-react';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LoadingSpinner } from '@/components/ui/spinner';
import LeniQAnswer from '../LeniQAnswer';
import useLeniQContext from 'utils/hooks/useLeniQContext';
import LeniQQuestion from '../LeniQQuestion';

interface LeniQChatProps {
  handleQuestionClick: (question: string) => void;
}

const QUESTIONS = [
  'What are the top 2 reasons I lost a lead?',
  'How many showings had I had in the last month?',
  'How many buildings do I have?',
];

const formSchema = z.object({
  question: z.string().min(1, {
    message: 'A question cannot be empty',
  }),
});

const LeniQChat: React.FC<LeniQChatProps> = ({ handleQuestionClick }) => {
  const { user: currentUser } = useUser();
  const {
    state: { question: questionAsked },
  } = useLeniQContext();
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      question: '',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    handleQuestionClick(values.question);
    setLoading(false);
  }

  return (
    <div className="p-4 h-full flex flex-col justify-between">
      {!questionAsked && (
        <div>
          <h2 className="display-md font-bold bg-gradient-to-r from-[#ACDFFD] via-[#3F5E75] to-[#101828] bg-clip-text text-transparent bg-[length:200%_200%] animate-scroll-gradient">
            Hi, {currentUser?.firstName}
          </h2>
          <h4 className="text-lg font-bold">Ask LeniQ about your portfolio</h4>
          <div className="flex flex-col gap-2 mt-5">
            {QUESTIONS.map(question => (
              <LeniQQuestion key={question} question={question} onClick={() => handleQuestionClick(question)} />
            ))}
          </div>
        </div>
      )}
      {loading && (
        <div>
          <LoadingSpinner className="text-gray-400" height={80} width={80} />
        </div>
      )}
      {!loading && questionAsked && (
        <>
          <span className="bg-gray-200 text-primary-900 py-2 px-4 rounded-full text-center">{questionAsked}</span>
          <LeniQAnswer question={questionAsked} />
        </>
      )}
      <div className="">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex items-center justify-between p-1 pr-3 m-0 border border-input rounded-full shadow-md max-w-[3500px] z-100 bg-white-50">
            <FormField
              control={form.control}
              name="question"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormControl>
                    <CommentInput placeholder="Add a comment" className="w-[275px]" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <button type="submit" className="cursor-pointer p-[5px] bg-gray-400 rounded-full" disabled={loading}>
              <ArrowUp height={15} width={15} className="text-white-50" />
            </button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default LeniQChat;
