import { useUser } from 'contexts/UserContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GptApi } from 'services/api/gpt';
import { TableAccessContext } from 'contexts/TableAccessContext.tsx';
import Typewriter from 'typewriter-effect';
import { ProcessGptResponse, processGptResponse } from 'utils/LeniQ/processGptResponse';
import { Copy, ThumbsUp } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import LoadingSpinnerAlt from '@/components/ui/spinner-alternate';
import { getScreenCapture } from 'utils/LeniQ/getScreenCapture';
import { GptRunModelsData } from 'src/types/Services/GPT/gpt';
import { AxiosResponse } from 'axios';
import { User } from 'src/types/User/user';
import LeniQFeedbackModal from 'components/Modals/LeniQFeedbackModal';

interface LeniQAnswerProps {
  question: string;
}

const LeniQAnswer: React.FC<LeniQAnswerProps> = ({ question }) => {
  const { user: currentUser } = useUser();
  const tableAccessContext = useContext(TableAccessContext);
  const [loading, setLoading] = useState(true);
  const [questionAnswer, setQuestionAnswer] = useState<ProcessGptResponse | null>(null);
  const [hasRated, setHasRated] = useState(false);

  const { toast } = useToast();

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast({
        description: 'Copied to clipboard!',
        variant: 'success',
        duration: 2000,
      });
    } catch (error) {
      toast({
        description: 'Oops! Something went wrong.',
        variant: 'error',
        duration: 2000,
      });
    }
  };

  const isQuestionAboutExecutiveSummary = (question: string) => {
    return question.toLowerCase().includes('executive summary');
  };

  const askGptQuestion = useCallback(
    async (question: string) => {
      if (!currentUser) return;
      setLoading(true);
      try {
        const userPayload = {
          ...currentUser,
          tables: tableAccessContext?.state?.data?.tables,
        };
        const res: AxiosResponse<GptRunModelsData> = await GptApi.runModels(
          question,
          userPayload,
          isQuestionAboutExecutiveSummary(question) ? await getScreenCapture() : null
        );
        const answerInfo = processGptResponse(res);

        if (!answerInfo) {
          throw 'Something went wrong!';
        }
        setQuestionAnswer(answerInfo);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [currentUser, tableAccessContext?.state?.data?.tables]
  );

  useEffect(() => {
    askGptQuestion(question);
    setHasRated(false);
  }, [question, askGptQuestion]);

  const handlePositiveFeedback = async (
    questionId: string,
    user: User | null,
    setHasRated: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!user) return;
    try {
      await GptApi.giveFeedback({ success: true, questionId, user });
      toast({
        variant: 'success',
        description: 'Your feedback has been successfully submitted',
        duration: 3000,
      });
      setHasRated(true);
    } catch (error) {
      console.log(error);
      toast({
        variant: 'error',
        description: 'Something went wrong when giving your feedback - Please try again later',
        duration: 3000,
      });
      setHasRated(false);
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingSpinnerAlt />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col items-start mt-6 text-md">
      {questionAnswer && questionAnswer.summary && (
        <Typewriter
          options={{ delay: 10, cursor: '' }}
          onInit={typewriter => {
            typewriter.typeString(questionAnswer.summary as string).start();
          }}
        />
      )}
      <div className="flex items-center gap-3 mt-2">
        <ThumbsUp
          height={14}
          width={14}
          className={`cursor-pointer ${hasRated ? 'pointer-events-none text-gray-100' : 'text-gray-400'}`}
          onClick={() => handlePositiveFeedback(questionAnswer?.questionId as string, currentUser, setHasRated)}
        />
        <LeniQFeedbackModal
          questionId={questionAnswer?.questionId as string}
          setHasRated={setHasRated}
          hasRated={hasRated}
        />
        <Copy
          height={14}
          width={14}
          className="text-gray-400 cursor-pointer"
          onClick={() => copyToClipboard(questionAnswer?.summary as string)}
        />
      </div>
    </div>
  );
};

export default LeniQAnswer;
