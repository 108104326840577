import { RotateCcw, SlidersVertical } from 'lucide-react';

export const LOOKER_DASHBOARD_CATEGORIES = [
  {
    pageId: 'marketing',
    title: 'Marketing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'leasing',
    title: 'Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'management',
    title: 'Management',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'reporting',
    title: 'Revenue Management',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'reporting_leasing',
    title: 'Reporting Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'management_sandbox',
    title: 'Management Data',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'unit_turn',
    title: 'Unit Turn Data',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'unit_turn_entrata',
    title: 'Unit Turn',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'marketing_leasing_entrata',
    title: 'Marketing and Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'marketing_leasing_sandbox',
    title: 'Marketing and Leasing Data',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'budgeted_vs_actual',
    title: 'Budgeted vs. Actual',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'asset_management',
    title: 'Asset Management',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'cgi_vp_exec',
    title: 'VP Exec - Visualizations',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'cgi_leasing',
    title: 'Marketing and Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'cgi_vp_exec_sandbox',
    title: 'VP Exec - Tables',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'cgi_operations',
    title: 'Operations',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_operations',
    title: 'Operations',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_occupancy_and_leasing_performance',
    title: 'Occupancy and Leasing Performance',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_marketing',
    title: 'Marketing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_financial',
    title: 'Financial',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_revenue_management',
    title: 'Revenue Management',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'standard_executive_overview',
    title: 'Executive Overview',
    hide: false,
    dummy: false,
  },
];

export const TABLEAU_DASHBOARD_CATEGORIES = [
  {
    pageId: 'executiveSummary',
    title: 'Executive Summary',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'occupancyAndLeasing',
    title: 'Occupancy and Leasing',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'marketingActivity',
    title: 'Marketing Activity',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'workOrders',
    title: 'Work Orders',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'financialPerformance',
    title: 'Financial Performance',
    hide: false,
    dummy: false,
  },
  {
    pageId: 'residentActivity',
    title: 'Resident Activity',
    hide: false,
    dummy: false,
  },
];

export const toolLinks = [
  {
    pageId: 'bookings',
    title: 'Convert',
    icon: <RotateCcw height={22} width={22} />,
    link: '/dashboard/bookings',
    hide: false,
    dummy: false,
  },
  // {
  //   pageId: 'tickets',
  //   title: 'Engage',
  //   icon: <RefreshCcw height={22} width={22} />,
  //   link: '/dashboard/tickets',
  //   hide: false,
  //   dummy: false,
  // },
  // {
  //   pageId: 'income-management',
  //   title: 'Income Management',
  //   icon: <Coins height={22} width={22} />,
  //   link: '/dashboard/income-management',
  //   hide: false,
  //   dummy: false,
  // },
  {
    pageId: 'price',
    title: 'Revenue Management',
    icon: <SlidersVertical height={22} width={22} />,
    link: '/dashboard/price',
    hide: false,
    dummy: false,
  },
];

export const insightCategories = [
  {
    insightName: 'leasing',
    title: 'Leasing',
  },
  {
    insightName: 'management',
    title: 'Management',
  },
  {
    insightName: 'management_data',
    title: 'Management Data',
  },
  {
    insightName: 'marketing',
    title: 'Marketing',
  },
  {
    insightName: 'marketing_leasing_data',
    title: 'Marketing & Leasing Data',
  },
  {
    insightName: 'revenue_management',
    title: 'Revenue Management',
  },
  {
    insightName: 'tenants',
    title: 'Tenants',
  },
  {
    insightName: 'unit_turn_data',
    title: 'Unit Turn Data',
  },
];
